import React from 'react'

function Loader() {
  return (
    <div className="full-page-loader">
    <div className="loader"></div>
  </div>
  )
}

export default Loader